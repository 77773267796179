.header {
  text-align: left;
  margin-left: 3em;
  margin-top: 2em;
}

.title {
  font-size: 3em;
  font-weight: bold;
  color: #ff8e4d;
}

.icon_text_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2em;
  gap: 1em;
}

.privacy_policy_text {
  margin: 0rem 2rem;
  font-size: 1em;
}

@media (max-width: 768px) {
  .header {
    margin-left: 1em;
    margin-top: 1em;
  }

  .title {
    font-size: 2em;
  }

  .icon_text_wrapper {
    margin: 0 1em;
  }

  .privacy_policy_text {
    margin: 0 1em;
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .header {
    margin-left: 0.5em;
    margin-top: 0.5em;
  }

  .title {
    font-size: 1.5em;
  }

  .icon_text_wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }

  .privacy_policy_text {
    font-size: 1.2em;
  }
}
