.header {
  text-align: left;
  margin-left: 3em;
  margin-top: 2em;
}

.title {
  font-size: 3em;
  font-weight: bold;
  color: #ff8e4d;
}

.icon_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2em;
  gap: 0 1em;
}

._text {
  margin: 0 2rem;
  font-size: 1em;
}

.teamProfiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 1.2em;
}

.teamProfile {
  width: 20rem;
  text-align: center;
  padding: 1em;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profileImage {
  width: 100%;
  height: 55%;
  border-radius: 50%;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .header {
    margin-left: 1em;
    margin-top: 1em;
  }

  .title {
    font-size: 2em;
  }

  .icon_text {
    margin: 0 1em;
  }

  ._text {
    margin: 0 1em;
    font-size: 1.2em;
  }

  .teamProfiles {
    flex-direction: column;
    align-items: center;
  }

  .teamProfile {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .header {
    margin-left: 0.5em;
    margin-top: 0.5em;
  }

  .title {
    font-size: 1.5em;
  }

  .icon_text {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }

  ._text {
    font-size: 1.2em;
  }
}
