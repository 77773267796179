.ayodhya {
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
  
  .mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 10% 10%;
    margin-top: 2% !important;
    min-height: 58vh;
    flex-direction: row;
    width: 80%;
    border: 1px solid #E98146; /* Adjust color and width as needed */
    border-radius: 10px;
  }
  
  .features h2 {
    margin-top: 20px;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
    width: 95%;
  }
  
  .plusSign,
  .minusSign {
    margin-left: 8px;
    margin-bottom: 16px;
  }
  
  .cardItem {
    display: flex;
    margin-right: 0.5rem;
  }
  
  .formGroup input {
    flex: 1;
    width: 92%;
    display: table-column;
    margin-bottom: 0.8rem;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .formGroup input {
    border-color: #aaa;
  }

  .bookButton {
    background: linear-gradient(to right, #ffb64d, #ff714e);
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  
  .addButton {
    background: linear-gradient(to right, #ffb64d, #ff714e);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .dateBox {
    display: inline-block;
    padding: 5px 10px; /* Adjust padding as needed */
    border: 1px solid #ccc; /* Border style */
    border-radius: 4px; /* Border radius for rounded corners */
    background-color: #f9f9f9; /* Background color */
    /* Add any other styling you want */
  }

  .card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin: 1em 1em ;
  }
  
  .card_image {
    width: 95%;
    border-radius: 8px;
    margin: 1em 1em;
  }
  
  .card_title {
    font-size: 1.2em;
    font-weight: bold;
    color: #ff6600;
    margin-bottom: 8px;
  }
  
  .card_description {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 16px;
  }
  
  .card_footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
  }
  
  .card_shulk,
  .card_price {
    font-weight: bold;
  }

 .leftSection,
 .rightSection {
    flex: 1;
    margin: 0 1em;
}
  
  
  @media (max-width: 900px) {
    .mainContent {
      width: 80%;
      flex-direction: column;
      margin: 10% 10%;
      margin-top: -5%;
      align-items: center;
      align-self: center;
    }
    .header,
    .features,
    .listing {
      padding-left: 1em;
      padding-right: 1em;
      margin-bottom: 2rem;
    }
    .bookButton {
      width: 100%;
    }

    .card_title {
        font-size: 1em;
      }
    
      .card_description {
        font-size: 0.8em;
      }
    
      .card_footer {
        flex-direction: row;
        align-items: center;
      }

    .leftSection,
    .rightSection {
        margin: 1em 0;
    }
  }
  