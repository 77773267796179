.ayodhya {
  background-color: #fff1ea;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Center align items */
  padding: 0rem 2rem; /* Updated padding */
}

.title {
  width: 500px;
  font-size: 1.5em;
  font-weight: bold;
  color: #ff8e4d;
  margin: 10px 0px;
  text-align: start; /* Center align text */
}

.subtitle {
  width: 540px;
  font-size: 1em;
  color: #666;
  text-align: start; /* Center align text */
}

.iconTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1em;
}

.mainContent {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: flex-start; /* Center align items */
  gap: 2rem; /* Add gap between sections */
  margin: 0 2rem; /* Updated margin */
}

.features {
  width: 100%;
  min-height: 100px; /* Occupy full width */
  margin-bottom: 20px;
  margin-top: -25px;
}

.listing {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 40px; /* Add padding to create space from the corners */
}

.pujaImage {
  width: 1000px;
  height: 50vh;
  border-radius: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  object-fit: cover;
}
/* styles.css */

.textContainer {
  margin-top: 20px;
}
.colorText {
  color: blue; /* or any other color you prefer */
}

.reviewsTitle {
  font-size: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;
  text-align: start;
}

.scrollContainer {
  width: 90%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px; /* Adjust padding as needed */
}


.back {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Center align content */
  gap: 0.5em;
  margin-bottom: 1em;
}

.backButton {
  cursor: pointer;
  color: #666;
  text-align: center; /* Center align text */
}

.bookButton {
  width: 550px;
  padding: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  text-align: center;
  margin: 30px;
}
.tabs {
  width: 100%; 
  display: flex;
  justify-content: space-between; /* Distribute space evenly between tabs */
  margin-bottom: -3rem; /* Add space below tabs */
  padding: 0.5rem; /* Add padding to the container */
}
.line {
  margin-top: 10px; /* Position the line relative to the button */
  left: 0; /* Align the line to the left of the button */
  height: 4px; /* Set the height of the line */
  width: 30%; /* Make the line span the full width of the button */
  background-color: #ffB64d; /* Set the color of the line to white */

}
.line1 {
  margin-top: 10px; /* Position the line relative to the button */ 
  left: 0; /* Align the line to the left of the button */
  height: 4px; /* Set the height of the line */
  width: 30%; /* Make the line span the full width of the button */
  background-color: transparent; /* Set the color of the line to white */
}
.tabContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% / 4); /* Each column takes one quarter of the width */
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Update styles for individual tabs */
.tab {
  width: 100%; 
  cursor: pointer;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
}

.activeTab {
  width: 100%; 
  color: #000000;
  font-weight: bold;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.divider {
  width: 100%;
  margin-top: 6px;
  height: 1px;
  background-color: #00000026; /* You can change the color as needed */
}
.image1 {
  z-index: 1;
}
.image2 {
  z-index: 2;
  margin-left: -3%;
}
.image3 {
  z-index: 3;
  margin-left: -3%;
}
/* .text1 {
  position: 'absolute';
  bottom: 10;
  left: 10;
  color: 'white';
  font-size: 16;
} */
.text1 {
  font-size: 20px; /* Original font size */
  margin-left: 12px;
}
.image0 {
  position: 'absolute';
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: 'cover';
}

.container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 95%;
  }
  .colorText
  {
    color: #FF8E4C;
  }

footer {
  margin-top: auto;
}

.centralAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Optional: If you want the components to be stacked vertically */
}

.carouselContainer {
  width: 80%;  /* Adjust the container width as needed */
  margin: 0 auto;
}

.activeSection {
  background-color: #f0f0f0; /* Highlight color */
  border-left: 5px solid #007bff; /* Highlight border */
  padding-left: 10px; /* Adjust padding */
}

.highlightBox {
  border: 2px solid gold;
  padding: 10px;
  transition: border 0.5s ease-in-out;
  box-sizing: border-box;
  width: 100%;
}

.noBorder {
  border: none;
}

.hidden {
  opacity: 0;
}




@media (max-width: 1024px) {
  .mainContent {
    overflow: hidden;
  }
  .ayodhya {
    width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
  .title {
    width: 15em; 
  }
  .text1 {
    font-size: 1em; /* Original font size */
  }
  .subtitle {
    width: 20em;
    
  }
  .image1 {
    z-index: -1em;
  }
  .image2 {
    z-index: 2;
    margin-left: -1.5em;
  }
  .image3 {
    z-index: 3;
    margin-left: -1.5em;
  }
  .pujaImage {
    width: 45em;
    height: 30vh;
    border-radius: 1rem;
  }
  .listing {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .listing .pujaName {
    order: 1; /* Change the order to display pujaName below iconTextWrapper */
    margin-top: 1rem; /* Add margin for spacing */
  }

  .listing .pujaImage {
    margin-top: 1rem; /* Add margin for spacing */
  }

  .header,
  .features,
  .listing {
    padding: 1em; /* Updated padding */
  }
  .pujaDetailsParent{
    width:100%;
    overflow: hidden;
  }


  .iconTextWrapper {
    display: flex;
    gap: 1em;
    margin-left: -1em;
  }
  .pujaParentDiv{
    width:100%;
  }
  .bookButton {
    width: 20em;
  }
  .container {
    width: 50%;
  }
  .title {
    width: 12em;
  }
  .subtitle {
    width: 20em;
  
  }
  .centralAlign {
    flex-direction: column; /* Ensure vertical stacking on smaller screens */
    padding: 10px; /* Add some padding if needed */
  }

  .carouselContainer {
    width: 100%;  /* Full width for mobile devices */
}

.highlightBox {
  padding: 5px; /* Reduce padding for smaller screens */
  width: calc(100% - 10px);
}

.scrollContainer {
  width: 100%; /* Use full width on mobile */
  padding: 0 5px; /* Adjust padding for smaller screens */
}

}
@media (max-width: 767px) {
  .mainContent {
    overflow: hidden;
  }
  .ayodhya {
    width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
  .title {
    width: 15em; 
  }
  .text1 {
    font-size: 1em; /* Original font size */
  }
  .subtitle {
    width: 20em;
    
  }
  .image1 {
    z-index: -1em;
  }
  .image2 {
    z-index: 2;
    margin-left: -1.5em;
  }
  .image3 {
    z-index: 3;
    margin-left: -1.5em;
  }
  .pujaImage {
    width: 20em;
    height: 30vh;
    border-radius: 1rem;
  }
  .listing {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .listing .pujaName {
    order: 1; /* Change the order to display pujaName below iconTextWrapper */
    margin-top: 1rem; /* Add margin for spacing */
  }

  .listing .pujaImage {
    margin-top: 1rem; /* Add margin for spacing */
  }

  .header,
  .features,
  .listing {
    padding: 1em; /* Updated padding */
  }
  .pujaDetailsParent{
    width:100%;
    overflow: hidden;
  }


  .iconTextWrapper {
    display: flex;
    gap: 1em;
    margin-left: -1em;
  }
  .pujaParentDiv{
    width:100%;
  }
  .bookButton {
    width: 20em;
  }
  .container {
    width: 100%;
  }
  .title {
    width: 12em;
  }
  .subtitle {
    width: 20em;
  
  }
  .colorText
  {
    color: #FF8E4C;
  }
  .centralAlign {
    flex-direction: column; /* Ensure vertical stacking on smaller screens */
    padding: 10px; /* Add some padding if needed */
  }

  .carouselContainer {
    width: 100%;  /* Full width for mobile devices */
}

.highlightBox {
  padding: 5px; /* Reduce padding for smaller screens */
  width: calc(100% - 10px);
}

.scrollContainer {
  width: 100%; /* Use full width on mobile */
  padding: 0 5px; /* Adjust padding for smaller screens */
}
}
