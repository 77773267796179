.footer {
  padding: 20px 0;
  margin-top: -1rem;
  background-color: #ff8e4d;
  color: white;
}

.footer_content,
.footer_base {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
}

.footer_content {
  align-items: flex-start;
}

.logo_description_container,
.footer_contact,
.footer_social_links {
  display: flex;
  align-items: center;
}

.logo_description_container {
  max-width: 768px;
  flex-direction: column;
  align-items: flex-start;
}

.footer_logo {
  width: 50px;
  margin-right: 20px;
}

.footer_description {
  font-size: 1em;
}

.footer_contact {
  margin-left: auto;
  flex-direction: column;
  align-items: flex-end;
}

.contact_info,
.footer_policy_links {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: 5px;
}

.contact_info {
  margin-bottom: 2rem;
}

.footer_social_links {
  gap: 30px;
}

.social_icon {
  width: 30px;
  height: 30px;
}

.footer_base {
  margin-top: 20px;
  font-size: 0.9em;
  flex-wrap: wrap;
  gap: 20px;
}

.spacer {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.rights_reserved {
  margin-right: 2rem;
}

.footer_policy_links {
  flex-direction: row;
  gap: 1rem;
  margin-right: 5px;
}

a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

@media (max-width: 768px) {
  .footer_content {
    flex-direction: column;
    align-items: center;
  }

  .logo_description_container {
    text-align: center;
  }

  .footer_logo {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer_contact {
    margin-left: 0 !important;
    align-items: flex-start;
    text-align: center;
  }

  .footer_policy_links {
    gap: 0.5rem;
    margin-right: 3px;
  }

  .contact_info {
    margin-bottom: 20px;
  }
  a {
    color: white;
    text-decoration: none;
    font-size: 0.7em;
  }
}
