.profileContainer {
  display: flex;
  background-color: #fff1ea;
  height: 90vh;
}

.mainContent {
  display: flex;
  width: 100%;
}

.panel {
  width: 15%;
  background-color: #ff8e4d !important;
  padding: 1rem;
}

.panelOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.c {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
}

.panel button {
  margin-bottom: 10px;
  padding: 0.5rem 1rem;
  width: 100%;
  border: none;
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.panel button:hover {
  background-color: #ff714e;
}

.footer_contact {
  position: absolute;
  bottom: 0;
  flex-direction: column;
  color: white;
  margin: 1rem 1rem;
}

.contact_info,
.footer_policy_links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer_contact h3 {
  margin-bottom: 10px;
}

.footer_social_links {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social_icon {
  width: 30px;
  height: 30px;
}

.contentArea {
  width: 85%;
  background-color: #fff1ea;
  overflow-y: auto;
}

.profileForm {
  max-width: 700px;
  margin: 0 auto;
  padding: 6rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileForm input {
  width: 75%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.profileForm select {
  width: 78%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submitButton,
.logoutButton {
  width: 80%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  color: white;
  font-weight: bold;
}

.submitButton {
  background: linear-gradient(to right, #ffb64d, #ff714e);
}

.logoutButton {
  background-color: #ff2525;
  color: white;
}

.orderCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.status {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.2rem 0.2rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #ff8e4d;
}

.image {
  width: 18%;
  border-radius: 10px;
}

.detailsCard {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.details {
  padding: 0 1rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pujaname {
  font-size: 2rem;
  color: #ff714e;
  font-weight: bold;
  order: -1;
}

.price_container {
  gap: 1rem;
  width: 100%;
}

.border {
  border-top: 1px solid #8d8d8d;
}

.pujadescription {
  font-size: 1.2rem;
  color: grey;
}

.price_text {
  font-size: 1.2rem;
  margin-top: 5px;
  font-weight: bold;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5px;
}
.orderdate {
  font-size: 1em;
  color: black;
  font-weight: bold;
}

@media (max-width: 768px) {
  .profileForm {
    max-width: 80%;
  }

  .panel {
    width: 15%;
    padding: 0.75rem;
  }

  .panel button {
    padding: 0rem 0rem;
  }

  .submitButton,
  .logoutButton {
    font-size: 0.9rem;
  }

  .profileForm h2,
  .profileForm h3 {
    font-size: 1rem;
  }

  .profileForm {
    padding: 1rem;
  }

  .profileForm input {
    width: 75%;
  }

  .profileForm select {
    width: 82%;
  }

  .c {
    font-size: 1.25rem;
  }

  .pujaname,
  .pujadescription,
  .price {
    font-size: 0.7rem;
  }
  .orderdate {
    font-size: 0.7em;
    color: black;
    font-weight: bold;
  }

  .footer_contact {
    visibility: hidden;
  }

  .status {
    font-size: 0.6rem;
  }

  .statusIcon {
    width: 1rem;
    height: 1rem;
  }

  .image {
    width: 50%;
  }

  .profileForm input {
    padding: 8px;
  }
}
