.ayodhya {
  background-image: url("../../../assets/background/background.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2em;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  color: #ff8e4d;
  margin: 10px 0;
}

.subtitle {
  font-size: 1em;
  color: #666;
}

.iconTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.mainContent {
  display: flex;
  justify-content: space-between;
  margin: 2em 2em;
  min-height: 58vh;
}

.features {
  flex: 1;
}

.features h2 {
  margin-top: 20px;
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 95%;
}

.plusSign,
.minusSign {
  margin-left: 8px;
  margin-bottom: 16px;
}

.radioGroup {
  display: flex;
  margin-bottom: 5px;
  font-weight: bold;
}

.cardContainer {
  display: flex;
  font-weight: normal;
  justify-content: space-between;
}

.cardItem {
  display: flex;
  margin-right: 0.5rem;
}

.formGroup input {
  flex: 1;
  width: 92%;
  display: table-column;
  margin-bottom: 0.8rem;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.formGroup input {
  border-color: #aaa;
}

.listing {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summaryCard {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 80%;
  max-width: 25rem;
  text-align: center;
  padding: 1rem;
}

.summaryTitle {
  color: #000;
  font-size: 1.1em;
}

.pujaImage {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.pujaTitle {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 5px;
}

.price {
  font-size: 1em;
  color: #666;
  margin-bottom: 5px;
}

.original {
  color: #999;
  border-top: 1px solid #ddd;
  padding-top: 5px;
}

.cod {
  color: #8d8d8d;
}

.discount {
  color: red;
}

.total {
  font-size: 1em;
  color: #666;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px 0px;
  font-weight: bold;
}

.bookButton {
  background: linear-gradient(to right, #ffb64d, #ff714e);
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-align: center;
}

.couponButton {
  background: linear-gradient(to right, #ffb64d, #ff714e);
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.7rem 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-align: center;
}

.addButton {
  background: linear-gradient(to right, #ffb64d, #ff714e);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-align: center;
}
.dateBox {
  display: inline-block;
  padding: 5px 10px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Border style */
  border-radius: 4px; /* Border radius for rounded corners */
  background-color: #f9f9f9; /* Background color */
  /* Add any other styling you want */
}


footer {
  margin-top: auto;
}

.radioGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1em;
}

.radioLabel {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.radioInput {
  margin-right: 0.5em;
  transform: scale(1.2);
}


@media (max-width: 768px) {
  .mainContent {
    flex-direction: column;
    margin: 1em 1em;
  }
  .header,
  .features,
  .listing {
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 2rem;
  }
  .bookButton {
    width: 100%;
  }
}
