.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff1ea;
  height: 100vh;
  padding: 20px;
}

.contactForm {
  width: 100%;
  max-width: 400px;
  padding: 4rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.contactForm h2,
.contactForm h3 {
  text-align: center;
  margin-bottom: 20px;
}

.contactForm label {
  display: block;
  margin-bottom: 10px;
}

.contactForm input {
  width: 95%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submitButton {
  width: 100%;
  padding: 12px;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.logoutButton {
  width: 100%;
  padding: 12px;
  background-color: #ff2525;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.closeButton {
  margin-top: 20px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contactForm {
    padding: 20px;
    max-width: 70%;
  }

  .submitButton,
  .closeButton {
    font-size: 0.9rem;
  }

  .contactForm h2,
  .contactForm h3 {
    font-size: 1rem;
  }

  .contactForm input {
    padding: 10px;
  }
}
