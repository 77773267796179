.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 2px 10px 5px 0px rgba(0, 0, 0, 0.2);
}

.logo {
  height: 50px;
}

.buttons {
  display: flex;
  justify-content: right;
  align-items: center;
}

.nav_links a,
b {
  text-decoration: none;
  color: #292929 !important;
  margin: 0 8px;
  font-weight: bold;
  cursor: pointer;
}

.download_button {
  padding: 8px 8px;
  margin: 0 5px;
  font-weight: bold;
  background-color: white;
  color: #ff7b25 !important;
}

.login_button {
  padding: 8px 8px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  min-height: 30px;
}

.referral {
  color: #2c8502 !important;
}

.login_button {
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
}

@media (max-width: 768px) {
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 4px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }
  
  .logo {
    height: 30px;
    margin-bottom: 10px;
  }

  .nav_actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30px;
  }
  .download_button {
    width: auto;
    font-size: 0.8em;
    padding: 2px;
    align-items: center;
  }

  .navbar_pujas {
    padding: 2px;
    font-size: 0.8em;
    height: 20px;
  }

  .login_button {
    padding: 2px 10px;
  }

  .logedin_text {
    font-size: 0.8em;
    padding: 4px 2px; 
  }

  .nav_links a,b {
  text-decoration: none;
  color: #292929 !important;
  margin: 0 1px;
  font-weight: bold;
  cursor: pointer;
}
}
