.textLine {
  font-size: 1.5rem;
  font-weight: bold;
  color: #FF8E4C;
  text-align: center;
  margin-bottom: 1vh;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  font-size: 1vw; /* Responsive font size */
  font-weight: bold;
  color: #333;
  margin-top: 1vh;
  /* margin-right: 20.6vw;
  margin-bottom: -2vh; */
}
.timeContainer {
  display: flex; /* Display boxes in a row */
  justify-content: center; 
}

.timeBox {
  display: flex; /* Ensure text is displayed below time */
  flex-direction: column; /* Text below time */
  align-items: center; /* Center text */
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px; /* Add space between boxes */
  background-color: #FF8E4C; /* Set background color */
}

.timeText {
  font-size: 1rem;
  font-weight: bold;
  color: white; /* Set text color */
}

.timeLabel {
  font-size: 1rem;
  color: white; /* Set text color */
  margin-top: 2px; /* Spacing between time and text */
}


@media (max-width: 900px) {
  .timer {
    font-size: 6vw; 
    margin-top: 1vh; 
    margin-left: 6vw;
    margin-bottom: -1vh;
  }

  .textLine {
    font-size: 5vw;
    margin-bottom: 2vh;
  }
}
