.ayodhya {
  background-color: #fff1ea;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
}

.title {
  width: 90%;
  font-size: 1.5em;
  font-weight: bold;
  color: #ff8e4d;
  margin: 10px 0;
}

.subtitle {
  font-size: 1em;
  color: #666;
}

.iconTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.mainArea {
  background-image: url("../../../assets/background/bg5.png");
  background-size: cover;
  background-position: center;
}

.mainContent {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 40px;
  margin: 2em 24% 20rem 24%;
  padding: 2em;
  min-height: 50vh;
}

.features {
  flex: 1;
  color: grey;
  font-size: 0.8rem;
}

.features_items {
  margin-bottom: 0.4rem;
}

.listing {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pujaImage {
  display: flex;
  width: 100%;
  max-height: 50%;
  border-radius: 10px;
}

h2 {
  color: black;
}

.section {
  width: 50%;
  margin: 1em 1em;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 1em;
}

.backButton {
  cursor: pointer;
  color: #666;
}

.bookButton {
  width: calc(50% - 5em);
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  margin-bottom: 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  text-align: center;
}

footer {
  margin-top: auto;
}

.authForm {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  margin: 10px;
}

.authForm h2,
.authForm h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.authForm label {
  display: block;
  margin-bottom: 10px;
}

.phoneInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.authForm input {
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.authInput {
  margin-bottom: 12px;
  font-size: 16px;
}

.submitButton {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.closeButton {
  margin-top: 20px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.pujaTitle {
  color: #ff714e;
  font-size: 1.2em;
  margin: 0.2rem 0;
  font-weight: bold;
  margin: 0.5em 0.1em;
}

.puja_description {
  color: #565F6D;
  padding: 0.5em 0.1em;
}

.shulk_container {
  padding: 0.5em 0.1em;
}

.pay_shulk{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.card_details {
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.location {
  color: #E98146;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.date {
  color: #E98146;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.bookedby {
  color: #6E7682;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.rating {
  color: #545454;
  font-weight: bold;
}

.location_image {
  margin-right: 4px;
}
.date_image {
  margin-right: 4px;
}

.peoplelabel {
  color: #E98146;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
}


@media (max-width: 968px) {
  .mainContent {
    flex-direction: column;
    margin: 1em 1em;
    padding: 1em;
  }

  .title {
    font-size: 1em;
  }

  .pujaImage {
    padding: 1%;
  }

  .pujaTitle {
    color: #ff714e;
    font-size: 0.8em;
    margin: 0.2rem 0;
    font-weight: bold;
    margin: 0.5em 0.1em;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .header,
  .features,
  .listing {
    padding-left: 1em;
    padding-right: 1em;
  }

  .bookButton {
    width: 100%;
  }

  .loginImage {
    height: 0px;
    width: 0px;
  }

  .authForm {
    margin: 4px;
    margin-top: 0px;
    padding: 5px;
    padding-top: 0px !important;
  }

  .pay_shulk {
    margin-bottom: 1px;
  }
}
