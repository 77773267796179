.comingsoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff1ea;
  height: 100vh;
  padding: 20px;
}

.button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  font-size: 1rem;
}

.dialogBox {
  position: absolute;
  width: 100%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.dialogBox form {
  display: flex;
  flex-direction: column;
}

.dialogBox label {
  display: block;
  margin-bottom: 10px;
}

.dialogBox input {
  width: 92%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submitButton {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .dialogBox {
    padding: 2.5rem;
    max-width: 70%;
  }

  .button,
  .submitButton {
    font-size: 0.9rem;
  }

  .dialogBox label {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 500;
  }

  .dialogBox input {
    padding: 10px;
  }
}
