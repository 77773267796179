.result {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff1ea;
  padding: 20px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin-bottom: 5rem;
}

.card {
  background-image: url("../../assets/result/bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  background-color: #fff;
}

.section,
.aside {
  flex: 1;
  padding: 1rem;
}

.title {
  font-size: 1.5em;
  color: black;
  margin: 0.5rem 0;
}

b,
.summaryTitle {
  font-size: 1em;
  color: #ff714e;
}

.features {
  color: #666;
}

.pujaImage {
  width: 100%;
  border-radius: 10px;
}

.pujaTitle {
  color: #ff714e;
  font-size: 1.5em;
  margin: 0.2rem 0;
}

.total {
  font-size: 1em;
  color: #666;
  visibility: hidden;
  border-top: 1px solid #ddd;
}

.button {
  padding: 1rem;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.bottom_part {
  text-align: center;
  margin-top: 2rem;
  border-top: 2px solid #ddd;
}

.referral_code_container {
  background-image: url("../../assets/result/bg.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  align-items: center;
  justify-self: center;
  padding: 0rem 35%;
}

.referral_code {
  margin-top: 1rem;
  text-align: center;
  align-items: center;
  justify-self: center;
  padding: 1rem 0.2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.referral_code input {
  padding: 10px 5px;
  font-size: 1em;
  background-color: white;
  border: 2px dashed #ff8e4d;
  color: #fc792e;
  text-align: center;
}

.spacing {
  margin: 0 0.5rem;
}

@media (max-width: 768px) {
  .cardContainer {
    width: 90%;
  }

  .card {
    flex-direction: column;
  }

  .bottom_part {
    padding: 0 10px;
  }

  .referral_code_container {
    background-color: white;
  }

  .referral_code input {
    padding: 10px 5px;
    font-size: 0.5em;
  }

  .referral_code img {
    width: 1.2em;
    height: 1.2em;
  }

  .balance_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .bottom_part p {
    font-size: 0.75rem;
  }

  .bottom_part {
    padding: 30px;
  }
}
