.explore {
  background-image: url("../../assets/background/download_background.png");
  background-size: cover;
  background-position: center;
  margin-top: -1em;
  height: auto;
}

.header {
  text-align: center;
}

.title {
  font-size: 3em;
  font-weight: bold;
  color: #ff8e4d;
}

.icon_text_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.list {
  display: flex;
  justify-content: center;
  gap: 5em;
}

.gimage {
  width: 20rem;
  height: 8rem;
}

.aimage {
  width: 17rem;
  height: 8rem;
}

.card {
  border-radius: 10px;
  transition: 0.3s;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-20px);
}

@media screen and (max-width: 800px) {
  .explore {
    width: 100%;
    height: 6em;

  }

  .header {
    text-align: center;
    padding: 0%;
  }

  .title {
    font-size: small;
  }

  .icon {
    width: 1rem;
    height: 1rem;
  }

  .icon_text_wrapper {
    flex-direction: row;
    margin-top: -1em;
  }

  .list {
    display: flex;
    gap: 0.5em;
    flex-direction: row;
    justify-content: center;
  }

  .gimage {
    width: 4rem;
    height: 2rem;
    align-items: center;
  }

  .aimage {
    width: 4rem;
    height: 2rem;
    align-items: center;
  }

  .card {
    border-radius: 10px;
  }
}
