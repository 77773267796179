.saved_address_container {
  margin: 2rem;
}

.addressBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
}

h1 {
  color: #ff8e4d !important;
}

.addressBox .addressName {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0.5rem 0 0 2rem;
  color: #ff7b25 !important;
}

.addressBox .addressDetails {
  padding: 0.5rem 2rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
}

.addressBox .phoneNumber {
  font-size: 1.3rem;
  margin: 0 0 1rem 2rem;
  font-weight: 300;
}

.remove_button {
  border: #ff7b25 1px solid;
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
  color: #ff7b25;
}

.download_button {
  border: #ff7b25 1px solid;
  background-color: #fff1ea;
  border-radius: 10px;
  padding: 10px 20px;
  color: #ff7b25;
}

@media only screen and (max-width: 768px) {
  .addressBox {
    flex-direction: column;
  }

  .addressBox .addressName {
    margin-left: 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .addressBox .addressDetails {
    padding: 0.5rem;
    font-size: 1rem;
    text-align: center;
  }

  .addressBox .phoneNumber {
    margin-left: 0;
    font-size: 1rem;
    text-align: center;
  }
}
