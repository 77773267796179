.referral {
  margin: 0 auto;
  min-height: 85vh;
  background-color: #ffe8d9;
}

.top_part {
  position: relative;
  height: 30vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: white;
}

#general {
  background-image: url("../../assets/referral/generalbg.png");
}

#merchant {
  background-image: url("../../assets/referral/merchantbg.png");
}

.top_text {
  font-size: 4rem;
  font-style: italic;
  font-family: playfair display;
  font-weight: bold;
}

h1 {
  color: #fc792e !important;
}

.top_subtext {
  font-size: 1rem;
}

.middle_part {
  width: 100%;
  padding: 1.5rem 0rem;
  background-color: white;
  color: #ff8e4d;
  text-align: center;
}

.middle_options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
}

.middle_options div {
  width: 80%;
}

.spacing {
  margin: 0 0.5rem;
}

.icon {
  width: 2em;
  height: 2em;
  margin: 0 auto;
}

.middle_options p {
  font-size: 0.8em;
  color: black;
}

.bottom_part {
  text-align: center;
  margin-top: 10px;
}

.referral_code_container {
  display: grid;
  text-align: center;
  align-items: center;
  justify-self: center;
}

.referral_code {
  text-align: center;
  align-items: center;
  justify-self: center;
  padding: 1rem 2rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.referral_code img {
  width: 2em;
  height: 2em;
}

.referral_code p {
  font-size: 1em;
}

.referral_code input {
  padding: 10px 5px;
  font-size: 1em;
  background-color: white;
  border: 2px dashed #ff8e4d;
  color: #fc792e;
  text-align: center;
}

.balance_container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 3rem;
}

.balance_container p {
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}

.balance_friends {
  margin-top: 1rem;
  text-align: center;
}

.balance_text {
  margin-top: 10px;
  padding: 1.5rem 1rem;
  font-size: 0.8em;
  background-color: #ffbf9b;
  border: 2px solid #ff8e4d;
  color: black;
  text-align: center;
}

.mbalance_text {
  margin-top: 10px;
  padding: 1.5rem 1rem;
  font-size: 0.8em;
  color: black;
  text-align: center;
}

.referral_total {
  padding: 1rem 2rem;
  border-radius: 20px;
}

.balance_total {
  border: 2px solid #ff8e4d;
  padding: 1rem 5rem;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.withdraw_button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
}

.m_text {
  font-size: 0.8em;
  color: black;
  text-align: center;
}

@media (max-width: 768px) {
  .middle_options {
    flex-direction: row;
  }

  .middle_part h1 {
    font-size: 1.2rem;
  }

  .middle_options img {
    width: 1.5em;
    height: 1.5em;
  }

  .middle_options h3 {
    font-size: 0.8rem;
  }

  .middle_options p {
    font-size: 0.6rem;
  }

  .middle_options div {
    width: auto;
  }

  .referral_code input {
    padding: 0.2rem 0.1rem;
    font-size: 1em;
  }

  .referral_code {
    display: flex;
    padding: auto;
    padding-left: 0.5rem;
  }

  .balance_container input {
    padding: 0.3rem 0.1rem;
    font-size: 0.5em;
  }

  .top_text {
    top: 30%;
    font-size: 1.5rem;
  }

  .top_subtext {
    top: 70%;
    font-size: 0.7rem;
    text-align: center;
  }

  .top_part {
    height: 20vh;
  }

  .bottom_part {
    padding: 0 10px;
  }

  .referral_code_container {
    background-color: white;
  }

  .referral_code input {
    padding: 10px 5px;
    font-size: 0.5em;
  }

  .referral_code img {
    width: 1.2em;
    height: 1.2em;
  }

  .balance_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .bottom_part p {
    font-size: 0.75rem;
  }

  .bottom_part {
    padding: 30px;
  }
}
