.body {
  font-family: "Inter";
}

.result {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    border: solid #E98146;
    width: 50%;
    margin-bottom: 5rem;
    
  }
  
  .card {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .section,
  .aside {
    flex: 1;
    padding: 1rem;
  }
  
  .title {
    font-size: 1.5em;
    color: black;
    padding: 1rem;
  }

  .features {
    color: #666;
  }
  
  .puja_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pujaImage {
    width: 70%;
    border-radius: 10px;
  }
  
  .pujaTitle {
    color: #ff714e;
    font-size: 1.5em;
    width: 100%;
    padding: 0.3rem 1rem;
  }

  .title_container {
      margin-bottom: 10px;
      margin-right: 10px;
  }

  .puja_description {
      color: #565F6D;
      padding: 0.5rem 1rem;
  }

  .book_button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
  }
  .book_button {
    width: 100%;
    padding: 0.7rem 1rem;
    background-color: #ff8e4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
    align-items: center;
  }

  .shulk_container {
    padding: 1rem;
  }

  .couponcode_container {
    padding: 1rem;
  }
  
  .pay_shulk,
  .total_shulk {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  
  .total_shulk {
    margin-top: 1rem;
    border-top: 1px solid #ddd;
    padding-top: 0.5rem;
  }
  
  .additional_details {
    background-color: #fcecdc;
    padding: 0.8rem 0.5em;
    border-radius: 4px;
    margin-top: 1rem;
    font-size: 14px;
    color: #555;
  }

  .radioGroup {
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
    margin: 1em 1em;
  }

  .radioLabel {
    display: flex;
    align-items: center;
    margin-right: 1em;
    margin-left: 1em;
  }

  .book_button:disabled {
    background-color: #cccccc; /* Faded color when disabled */
    color: #666666; /* Optional: Adjust the text color when disabled */
    cursor: not-allowed; /* Show a "not allowed" cursor */
    opacity: 0.6; /* Optional: Add some opacity for a more faded look */
  }

  .coupon_code_input_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
  }
  
  .coupon_code_input {
    width: 60%;
    padding: 0.5rem;
    font-size: 16px;
    border: 2px solid #E98146;
    border-radius: 5px;
    box-shadow: none;
  }
  
  .apply_button {
    padding: 0.7rem 5rem;
    background-color: #ff8e4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0.5rem;
    align-items: center;
  }
  
  .apply_button:hover {
    background-color: #ff8c69;
  }

  @media (max-width: 768px) {
    .result {
      padding: 10px;
    }
  
    .cardContainer {
      width: 80%;
      margin-bottom: 2rem;
      margin-left: 10%;
      margin-right: 10%;
    }
  
    .card {
      flex-direction: column;
    }
  
    .pay_shulk,
    .total_shulk {
      font-size: 14px;
    }
  
    .additional_details {
      font-size: 13px;
    }
  
    .radioGroup {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1em;
    }
  
    .radioLabel {
      margin: 0.5em 0;
    }
  
    .pujaImage {
      width: 100%;
      border-radius: 10px;
    }
  
    .book_button {
      padding: 0.5rem 0.8rem;
    }

    .pujaTitle {
      color: #ff714e;
      padding: 0.3rem 1rem;
      margin: 1px 10px !important;
    }

  .coupon_code_input_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem !important;
  }

  .coupon_code_input {
    width: 50% !important;
    box-shadow: none;
    border-radius: 5px;
    font-size: 16px;
    
  }
  .apply_button {
    width: 30% !important;
    padding: 0.7rem 1rem;
    font-size: 10px;
  }
  .coupon_code_input::placeholder {
    font-size: 10px; /* adjust the font size to your liking */
  }
  }
  