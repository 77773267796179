.ayodhya {
  background-color: #fff1ea;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  color: #ff8e4d;
  margin: 10px 0;
}

.subtitle {
  font-size: 1em;
  color: #666;
}

.iconTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.mainArea {
  background-image: url("../../../assets/background/bg5.png");
  background-size: cover;
  background-position: center;
}

.mainContent {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  margin: 2em 24% 20rem 24%;
  min-height: 50vh;
}

.title {
  color: white !important;
}

.features {
  flex: 1;
  color: grey;
  font-size: 0.8rem;
}

.features_items {
  margin-bottom: 0.4rem;
}

.listing {
  padding: 1rem;
  background-image: url("../../../assets/login/bg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  width: 45%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #ff8e4d;
  border-radius: 20px;
  text-align: center;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 1em;
}

.backButton {
  cursor: pointer;
  color: #666;
}

.bookButton {
  width: calc(50% - 5em);
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  margin-bottom: 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  text-align: center;
}

footer {
  margin-top: auto;
}

.authForm {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  margin: 10px;
}

.authForm h2,
.authForm h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.authForm label {
  display: block;
  margin-bottom: 10px;
}

.phoneInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.authForm input {
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.authInput {
  margin-bottom: 12px;
  font-size: 16px;
}

.submitButton {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.closeButton {
  margin-top: 20px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 968px) {
  .mainContent {
    flex-direction: column;
    margin: 1em 0.5em;
    padding: 1em;
  }

  .section {
    padding: 0.1rem;
    padding-top: 0rem;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.7em;
  }

  .title {
    font-size: 1em;
  }

  .pujaImage {
    padding-left: 15%;
  }

  .loginImage {
    height: 10rem;
    width: 10rem;
  }

  .section {
    width: 100%;
  }

  .header,
  .features,
  .listing {
    padding-top: 0em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .bookButton {
    width: 100%;
  }

  .authForm {
    margin: 4px;
    padding: 5px;
    padding-top: 8px !important;
  }
}
