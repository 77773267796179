.pujaReview {
    width: 95%;
    height: 130px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden; /* Ensures content doesn't overflow if longer than 300px */
    box-sizing: border-box;
    display: inline-block;
  }
  
  .pujaReviewContent {
    display: flex;
    flex-direction: column;
    margin: 2px 2px;
  }
  
  .pujaReviewHeader {
    padding: 5px 20px;
    display: flex;
    align-items: center;
  }
  
  .pujaReviewImageContainer {
    margin-right: 10px; /* Adjusted marginRight */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .pujaReviewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .pujaReviewInfo {
    flex: 1;
    min-width: 0;
  }
  
  .pujaReviewName {
    font-weight: bold;
    margin: 0;
    font-size: 14px;
  }
  
  .pujaReviewCity {
    margin: 0;
    font-size: 12px;
  }
  
  .pujaReviewComment {
    padding: 0 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow-y: auto;
  }
  
  .pujaReviewComment p {
    white-space: pre-wrap;
    margin: 0;
    font-size: 18px;
  }
  
  @media (max-width: 1024px) {
    .pujaReview {
      height: 22vh;
      padding: 0px;
      margin-bottom: 10px;
      margin: 0 10px 10px 0;
      border-radius: 10px;
    }
  
    .pujaReviewHeader {
      flex-direction: row;
      align-items: flex-start;
      padding: 5px 5px;
    }
  
    .pujaReviewImageContainer {
      width: 22px; /* Adjust image size for mobile */
      height: 22px; /* Adjust image size for mobile */
    }
  
    .pujaReviewName {
      font-size: 12px; /* Slightly increase font-size for better readability */
    }
  
    .pujaReviewCity {
      font-size: 12px; /* Slightly increase font-size for better readability */
    }
  
    .pujaReviewComment p {
      height: 110px;
      font-size: 13px; /* Slightly increase font-size for better readability */
      padding-bottom: 8px;
      overflow-y: scroll;
    }

    .pujaReviewImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }