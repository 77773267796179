.countdown_timer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .countdown_item {
    display: flex;
    align-items: baseline; /* Aligns the value and label */
    margin: 0.5rem;
  }
  
  .countdown_value {
    font-size: 3rem;
    margin-right: 0.2rem; /* Space between value and label */
    color: #FF8E4C;
    font-weight: bold;
  }
  
  .countdown_label {
    font-size: 1rem;
    color: #FF8E4C;
    margin: 0.1rem;
  }
  
  @media (max-width: 768px) {
    .countdown_timer {
      font-size: 1.5rem;
      border: solid 2px #FF8E4C;
    border-radius: 10px;
    }
  
    .countdown_value {
      font-size: 2rem;
    }
  
    .countdown_label {
      font-size: 0.8rem;
    }
  }