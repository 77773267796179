.parent_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 10%;
}

.progressBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #000;
  }
  
  .active .circle {
    background-color: #4caf50;
    color: #fff;
    border: none;
  }
  
  .line {
    flex-grow: 1;
    height: 2px;
    background-color: #ccc;
    position: relative;
    top: -18px;
  }
  
  .step p {
    margin-top: 10px;
    font-size: 14px;
    color: #999;
  }
  
  .active p {
    color: #4caf50;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .progressBar {
      max-width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      display: flex;
    }
  
    .circle {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  
    .step p {
      font-size: 10px;
    }

    .line {
        width: 2px;
        height: 30px;
        margin: 10px 0;
        background-color: #ccc;
      }
      .active p {
        color: #4caf50;
      }
  }
  
  @media (max-width: 768px) {
    .progressBar {
      flex-direction: row;
      width: 70% !important;
      margin-left: 10%;
      margin-right: 10%;
      justify-content: space-between;
      align-items: center;
    }
  
    .line {
      width: 2px;
      height: 2px;
      margin: 10px 0;
      background-color: #ccc;
      
    }
  
    .circle {
      width: 25px;
      height: 25px;
      font-size: 12px;
    }
  
    .step {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
      }
    
      .step p {
        margin-left: 10px;
        flex-grow: 1;
        text-align: left;
      }
    .active p {
        color: #4caf50;
      }
  }