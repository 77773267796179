.homepage {
  height:100vh;
  padding: 20px 20px;
}

.homepageImage {
  background-image: url("../../assets/background/homepage.png");
  background-size: cover;
  border-radius: 2rem;
  height: 75vh;
  background-position: center;
  /* padding: 50px 100px; */
}
.specialHeader {
  font-size: 2rem;
  font-weight: bold;
  color: #FF8E4C;
  text-align: center;
  margin-bottom: -3em;
  margin-top: 7rem;
  -webkit-background-clip: text;
  background-clip: text;
  background-color: #FF8E4C;
}
.hp_content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.hp_text_content {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.hp_content h1 {
  font-size: 4em;
  margin: 1rem 0rem;
  color: #2E353F !important;
  font-weight: bold;
}

.splashImage {
  margin: 0rem 0rem;
  margin-bottom: 1rem;
}

.hp_content p {
  font-size: 1.5em;
  margin: 1rem 0rem;
  color: #565F6D;
}

.weekly_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 100px 50px;
  margin: 50px 0;
}

.weekly_content {
  flex-basis: 50%;
  padding-right: 50px;
}

.weekly_content h2 {
  font-size: 3em;
  color: #333;
}

.weekly_content p {
  color: #666;
  margin-bottom: 1.5em;
}

.weekly_image img {
  width: 80%;
  border-radius: 20px;
}

.orange_button,
.white_button {
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.orange_button {
  background-color: white;
  border: #ff7b25 1px solid;
  color: #ff7b25;
}

.white_button {
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  margin-top: 1em;
  margin-bottom: 1em;
}

.white_button:hover,
.orange_button:hover {
  opacity: 0.75;
}

.buttons_group {
  display: flex;
  gap: 50px;
}
.banner_container {
  position: relative;
  text-align: center;
  color: #fff;
}

.banner_image {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.banner_img {
width: 100%;
height: auto;
object-fit: cover;
}

.marquee_container {
  background-color: #f58220;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  overflow: hidden;
}

.marquee_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 915px) {
  .homepage {
    padding: 1rem;
    background-image: url("../../assets/homepage1.png");
    height: 80vh;
  }

  .hp_text_content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: small;
  }

  .hp_content h1 {
    font-size: 2.5em;
  }

  .hp_content p {
    font-size: 1.5em;
  }

  .splashImage {
    max-width: 100%; /* Set maximum width to fill the container */
    height: 100%;
    display: block;
    margin-bottom: 1rem;
  }

  .weekly_section {
    flex-direction: column;
    padding: 50px 20px;
  }

  .weekly_content {
    flex-basis: 100%;
    padding-right: 0;
  }

  .weekly_content h2 {
    font-size: 2em;
  }

  .weekly_image img {
    width: 100%;
  }

  .buttons_group {
    flex-direction: column;
    gap: 20px;
  }

  .orange_button,
  .white_button {
    padding: 10px 20px;
  }
  .specialHeader {
    font-size: 2rem;
    margin-bottom: -2em;
    margin-top: 5rem;
  }

  .marquee_text {
    animation: marquee 10s linear infinite;
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

@media (max-width: 480px) {
  .hp_content h1 {
    font-size: 1.75em;
    margin-top: 2em;
  }
  .homepage {
    padding: 1rem;
    background-image: url("../../assets/homepage1.png");
    height: 40vh;
  }
  .hp_content p {
    font-size: 1em;
  }

  .weekly_content h2 {
    font-size: 1.5em;
  }

  .splashImage {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1rem;
  }
  .specialHeader {
    font-size: 1.75rem;
    margin-bottom: -18vh;
    margin-top: 35vh;
  }
}
