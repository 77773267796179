.body {
  font-family: "Inter";
}
.result {
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 20px;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: solid #E98146;
    width: 70%;
    margin-bottom: 5rem;
  }
  
  .card {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .section,
  .aside {
    flex: 1;
    padding: 1rem;
  }
  
  .title {
    font-size: 1.5em;
    color: black;
    margin: 0.5rem 0;
  }
  
  b,
  .summaryTitle {
    font-size: 1em;
    color: #ff714e;
  }
  
  .features {
    color: #666;
  }
  
  .pujaImage {
    width: 100%;
    border-radius: 10px;
  }
  
  .pujaTitle {
    color: #ff714e;
    font-size: 1.5em;
    margin: 0.2rem 0;
    font-weight: bold;
    margin: 1rem;
  }
  
  .button {
    padding: 1rem;
    background: linear-gradient(to right, #ffb64d, #ff714e);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .spacing {
    margin: 0 0.5rem;
  }

  .shulk_container {
    padding: 1rem;
  }
  
  .pay_shulk{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .puja_description {
    color: #565F6D;
    padding: 0.5rem 1rem;
}

.aside {
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .paymentSuccess {
    text-align: center;
  }
  
  .successImage {
    width: 100px;
    height: 100px;
  }
  
  .successText {
    font-size: 2em;
    color: green;
    margin-bottom: 1rem;
  }
  
  .button {
    padding: 1rem;
    background: linear-gradient(to right, #ffb64d, #ff714e);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }

  .payment_description {
      color: #555C70;
      font-size: 20px;
  }

  @media (max-width: 768px) {
    .cardContainer {
      width: 90%;
    }
  
    .card {
      flex-direction: column;
    }
  
    .bottom_part {
      padding: 0 10px;
    }
  
    .referral_code_container {
      background-color: white;
    }
  
    .referral_code input {
      padding: 10px 5px;
      font-size: 0.5em;
    }
  
    .referral_code img {
      width: 1.2em;
      height: 1.2em;
    }
  
    .balance_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  
    .bottom_part p {
      font-size: 0.75rem;
    }
  
    .bottom_part {
      padding: 30px;
    }

    .successImage {
    width: 40px;
    height: 40px;
  }

  .successText {
    font-size: 1.2em;
  }

  .button {
    font-size: 0.8rem;
    padding: 0.8rem;
  }
  }
  